import React from "react"
import PropTypes from "prop-types"

import Image from "../image/image"
import SimpleText from "../simpleText/simpleText"
import SocialMedia from "../socialMedia/socialMedia"

import * as styles from "./author.module.scss"
import { useTranslation } from "../../helpers/useTranslation"

const Author = ({ name, about, image, socialMedia, position, richAbout }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      {image?.asset && (
        <div className={styles.imageWrapper}>
          <Image image={image.asset} className={styles.image} alt={name} fit="cover" />
          <span className={styles.note}>{t("about the author")}</span>
        </div>
      )}
      <div className={styles.textWrapper}>
        <span className={styles.name}>{name}</span>
        <span className={styles.position}>{position}</span>
        {Boolean(socialMedia?.platform?.length) && <SocialMedia list={socialMedia.platform} />}
        {about && <p>{about}</p>}
        {Boolean(richAbout.length) && <SimpleText blocks={richAbout} />}
      </div>
    </div>
  )
}

Author.propTypes = {
  name: PropTypes.string.isRequired,
  about: PropTypes.string.isRequired,
  image: PropTypes.object,
  socialMedia: PropTypes.object,
  position: PropTypes.string,
  richAbout: PropTypes.array,
}

Author.defaultProps = {
  name: "",
  about: "",
  image: {},
  socialMedia: {},
  position: "",
  richAbout: [],
}

export default Author
