import React from "react"
import BaseBlockContent from "@sanity/block-content-to-react"
import FormattedLink from "../formattedLink/formattedLink"

const serializers = {
  types: {
    block(props) {
      return <p>{props.children}</p>
    },
  },
  marks: {
    link: ({ mark, children }) => {
      return <FormattedLink {...mark}>{children}</FormattedLink>
    },
  },
}

const SimpleText = ({ blocks }) => {
  return <BaseBlockContent blocks={blocks} serializers={serializers} />
}

export default SimpleText
